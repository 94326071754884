/*
fonts
*/

@font-face {
  font-family: 'Founders';
  src: url(../fonts/founders.eot);
  src: url('../fonts/founders.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/founders.woff') format('woff'), /* Moderne Browser */
        url('../fonts/founders.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/founders.svg#svgFontName') format('svg'); /* Ältere iOS-Geräte */
}


* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: Founders, sans-serif;
  font-size: 10pt;
}

.works {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
}

.exhibitions {
  position: absolute;
  top: 0;
  left: 260px;
  width: 250px;
}

.activeArchive {

}

.activeArchive a {
  text-decoration: none;
  color: black;
}

.activeArchive a:hover {
  text-decoration: underline;
}

.activeArchive p a {
  border-bottom: 1px dashed black;
}

.activeArchive p a:hover {
  text-decoration: none;
  border-bottom: 1px solid black;
}

.inactiveArchive {
  color: #9d9d9d;
}

.inactiveArchive p a {
  color: #9d9d9d;
  text-decoration: none;
}

a.activeArchiveLink {
  color: #fc0d1b;
}

.activeArchiveText {
  color: #fd8082;
}

/* grid */

.grid {
  position: absolute;
  left: 535px;
  top: 0;
  right: 0;
}

/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* grid-item */
.grid-item {
  min-width: 40px;
  min-height: 40px;
  float: left;
  padding: 5px;
}

.grid-item--medium {
  min-width: 40px;
  min-height: 40px;
}

.grid .grid-item--medium img {
  transform: scale(0.6);
  transform-origin: top left;
}

.grid .grid-item--medium.maximized img {
  transform: scale(1.0);
}

.grid-item--large {
  min-width: 40px;
  min-height: 40px;
}

.grid .grid-item--large img {
  transform: scale(0.8);
  transform-origin: top left;
}

.grid .grid-item--large.maximized img {
  transform: scale(1.0);
}

.grid-item a {
  text-decoration: none;
  border-bottom: 1px dashed black;
  color: black;
}

.grid-item a:hover {
  border-bottom: 1px solid black;
}

#content {
  position: absolute;
  top: 50px;
  left: 10px;
  right: 10px;
  width: 100%;
  font-family: "Courier New", "Lucida Console", Monospaced;
}

#content article {
  font-family: Founders, sans-serif;
  font-size: 14pt;
  margin-top: 50px;
}

#content article a {
  text-decoration: none;
  color: black;
}

#content article h2
{
  font-size: 14pt;
  text-decoration: underline;
  font-weight: normal;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 10px 0 10px;
  margin: 0 0 0 0;
  width: 100%;
  height: 40px;
  background-color: white;
  z-index: 100;
  font-size: 14pt;
}

.address {
  width: 250px;
  float: left;
}

.address a {
  text-decoration: none;
  color: black;
}

menu {
  margin: 0;
  padding: 0;
  max-width: 1250px;
}

menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

menu ul li {
  float: left;
}

menu ul li.menu-right {
  float: right;
}

menu ul li a {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 0 0 14px 16px;
  text-decoration: none;
}

menu ul li a.archive {
  padding: 0 210px 14px 10px;
}

menu ul li a.active {
  text-decoration: underline;
}

menu ul li a:hover {
  text-decoration: underline;
}

.imageTitle {
  visibility: collapse;
}

.largeInfo .description {
  max-width: 322px;
  font-family: "Courier New", "Lucida Console", Monospaced;
}

.largeInfo .description h1 {
  text-decoration: none;
  border-bottom: 1px dashed black;
  display: inline;
  font-weight: normal;
  font-size: 10pt;
}

.largeInfo img {
  margin-right: 8px;
  margin-bottom: 8px;
}

/* News-Notification */
#notification {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fc0d1b;
  font-weight: 900;
  font-size: 14pt;
  z-index: 1000;
  padding: 40px 0 0 10px;
  background-color: rgba(255, 255, 255, 0.5);
}

#notification .date {
  text-decoration: underline;
  margin-bottom: 10px;
}

#notification .info {
  font-size: 65pt;
  max-width: 1250px;
  margin-left: -5px;
  line-height: 0.95;
}

a.imgLink, a.imgLink:hover {
  border: none;
}


/* Mobile specific styles */
@media (max-width: 815px) {
  header {
    position: static;
    background-color: transparent;
    height: auto;
  }

  .address {
    float: none;
    width: auto;
  }

  menu {
    max-width: none;
  }

  menu ul li {
    float: none;
  }

  menu ul li a, menu ul li a.archive {
    padding: 0;
  }

  menu ul li.menu-right {
    float: none;
  }

  #content {
    position: static;
  }

  .works {
    position: static;
    width: auto;
  }

  .works .submenu {
    visibility: collapse;
    display: none;
  }

  .exhibitions {
    position: static;
    width: auto;
  }

  .exhibitions .submenu {
    visibility: collapse;
    display: none;
  }

  .grid {
    position: static;
  }

  #notification .info {
    font-size: 29pt;
    background-color: white;
  }
}